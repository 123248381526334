import React from 'react';
import styled from 'styled-components';

const ExpertiseCard: React.FC<{
  text: string;
}> = ({ text }) => {
  return (
    <Expertise>
      <Text>{typeof text === 'string' ? text : null}</Text>
    </Expertise>
  );
};

const Expertise = styled.div`
  background-color: #f0f6f3;
  padding: 18px;
  width: auto;
  margin-right: 10px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    padding: 10px;
    font-size: 18px;
  }
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 18px;
  letter-spacing: 0.01em !important;
  text-align: left;
  color: rgba(1, 100, 50, 1);
  margin: 0px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export default ExpertiseCard;
