import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';

import mapboxAdvisorCollect from 'queries/mapboxAdvisorCollect';
import searchfieldAdvisorCollect from 'queries/searchfieldAdvisorCollect';
import { AdvisorHybrid } from 'models/advisor';
import { CoordinatePair } from 'models/coordinate';
import HaversineFormula from 'utilities/haversineFormula';

import SearchScreen from 'components/Screens/SearchScreen';
import ResultsScreen from 'components/Screens/ResultsScreen';
import AdvisorScreen from 'components/Screens/AdvisorScreen';
import EditTeamMemberScreen from 'components/Screens/EditTeamMemberScreen';

const MainContainer: React.FC = () => {
  const urlVariables = queryString.parse(window.location.search);
  const [allAdvisors, setAllAdvisors] = React.useState<AdvisorHybrid[]>([]);
  const [filteredAdvisors, setFilteredAdvisors] = React.useState<
    AdvisorHybrid[]
  >([]);
  const [selectedAdvisor, setSelectedAdvisor] =
    React.useState<AdvisorHybrid | undefined>(undefined);
  const [userLocation, setUserLocation] =
    React.useState<CoordinatePair | undefined>(undefined);
  const [viewingMode, setViewingMode] =
    React.useState<undefined | string>(undefined);
  const [loading, setLoading] = React.useState(false);
  const [mapboxSearchType, setMapboxSearchType] =
    React.useState<undefined | string>(undefined);
  const [searchType, setSearchType] = React.useState('location');
  const [isPWAdvisorOnly, setIsPWAdvisorOnly] = React.useState(false);
  const [initialSearchPerformed, setInitialSearchPerformed] =
    React.useState(false);

  const collectAddressBasedAdvisors = async () => {
    setLoading(true);
    const contentfulObject = await mapboxAdvisorCollect();
    setAllAdvisors(contentfulObject);
    setLoading(false);
  };

  const collectSearchFieldAdvisors = async (searchString: string) => {
    setLoading(true);
    const contentfulObject = await searchfieldAdvisorCollect(searchString);
    setAllAdvisors(contentfulObject);
    setLoading(false);
  };

  React.useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === '/ipc-private-wealth') {
      setIsPWAdvisorOnly(true);
    }
  }, []);

  const with_pw_filter = (advisors: AdvisorHybrid[]) =>
    isPWAdvisorOnly
      ? advisors.filter(
          (advisor) => advisor.advisorFields.privateWealthAdvisor === true
        )
      : advisors;

  React.useEffect(() => {
    if (
      allAdvisors.length > 0 &&
      userLocation?.latitude &&
      searchType === 'location'
    ) {
      const nearbyAdvisors: AdvisorHybrid[] = [];
      allAdvisors.forEach((advisorTeam) => {
        if (
          (advisorTeam.advisorFields.primaryOffice &&
            HaversineFormula(
              userLocation.latitude,
              userLocation.longitude,
              advisorTeam.advisorFields.primaryOffice.lat,
              advisorTeam.advisorFields.primaryOffice.lon
            ) <= 25) ||
          HaversineFormula(
            userLocation.latitude,
            userLocation.longitude,
            advisorTeam.teamFields.lat,
            advisorTeam.teamFields.lon
          ) <= 25
        ) {
          if (
            advisorTeam.advisorFields.primaryOffice &&
            HaversineFormula(
              userLocation.latitude,
              userLocation.longitude,
              advisorTeam.advisorFields.primaryOffice.lat,
              advisorTeam.advisorFields.primaryOffice.lon
            ) <= 25
          ) {
            nearbyAdvisors.push(advisorTeam);
          }
          if (
            !advisorTeam.advisorFields.primaryOffice &&
            HaversineFormula(
              userLocation.latitude,
              userLocation.longitude,
              advisorTeam.teamFields.lat,
              advisorTeam.teamFields.lon
            ) <= 25
          ) {
            nearbyAdvisors.push(advisorTeam);
          }
        }
      });
      setFilteredAdvisors(with_pw_filter(nearbyAdvisors));
    }
    if (
      allAdvisors.length > 0 &&
      userLocation?.latitude &&
      searchType === 'advisorName'
    ) {
      setFilteredAdvisors(with_pw_filter(allAdvisors));
    }
    if (allAdvisors.length === 0 && !userLocation?.latitude) {
      setFilteredAdvisors([]);
    }
  }, [allAdvisors, userLocation, isPWAdvisorOnly]);

  const handleAdvisorSelect = (advisorTeam: AdvisorHybrid) => {
    setSelectedAdvisor(advisorTeam);
  };

  const goBack = () => {
    setSelectedAdvisor(undefined);
  };

  React.useEffect(() => {
    if (urlVariables.teamMemberSlug && urlVariables.advisorTeamSlug) {
      setViewingMode('editTeamMember');
    }
    if (selectedAdvisor) {
      setViewingMode('Details');
    }
    if (!urlVariables.teamMemberSlug && !selectedAdvisor) {
      setViewingMode('Search');
    }
  }, [selectedAdvisor, urlVariables]);

  return (
    <DesktopContainer>
      {viewingMode === 'Search' ? (
        <Scroller>
          <SearchScreen
            setUserLocation={setUserLocation}
            collectSearchFieldAdvisors={collectSearchFieldAdvisors}
            collectAddressBasedAdvisors={collectAddressBasedAdvisors}
            allAdvisors={allAdvisors}
            loading={loading}
            setMapboxSearchType={setMapboxSearchType}
            setSearchType={setSearchType}
            searchType={searchType}
            setInitialSearchPerformed={setInitialSearchPerformed}
          />
          <ResultsScreen
            filteredAdvisors={filteredAdvisors}
            userLocation={userLocation}
            handleAdvisorSelect={handleAdvisorSelect}
            setLoading={setLoading}
            mapboxSearchType={mapboxSearchType}
            initialSearchPerformed={initialSearchPerformed}
          />
        </Scroller>
      ) : null}
      {viewingMode === 'Details' && selectedAdvisor ? (
        <AdvisorScreen goBack={goBack} selectedAdvisor={selectedAdvisor} />
      ) : null}

      {viewingMode === 'editTeamMember' ? <EditTeamMemberScreen /> : null}
    </DesktopContainer>
  );
};

const DesktopContainer = styled.main`
  width: 100%;
  font-family: Roboto, 'Roboto Fallback' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  position: relative;
`;

const Scroller = styled.div`
  margin-bottom: 50px;
  overflow-x: scroll;
`;

export default MainContainer;
