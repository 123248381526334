import React from 'react';
import styled from 'styled-components';
import Thumbnail from 'assets/images/placeholder_thumb.svg';

const TeamMember: React.FC<{
  photo?: string;
  name?: string;
  credentials?: string;
}> = ({ photo, name, credentials }) => {
  return (
    <Member>
      <MemberPhoto alt={`${name}'s photograph`} src={photo ?? Thumbnail} />
      <Details>
        <MemberName>{name}</MemberName>
        <Credentials>{credentials}</Credentials>
      </Details>
    </Member>
  );
};

const Member = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  font-size: 20px !important;
  padding: 18px 8% 0px 0px;
  margin-right: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
  }
`;

const MemberPhoto = styled.img`
  height: 120px;
  filter: drop-shadow(0px 4px 12px rgba(37, 47, 35, 0.15));
  @media screen and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

const Details = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
  }
`;

const MemberName = styled.h2`
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 27px;
  letter-spacing: 0.01em !important;
  text-align: left;
  color: rgba(35, 41, 38, 1);
  padding: 0px;
  margin-bottom: 5px;
  text-transform: capitalize;
  margin-top: 0px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
`;

const Credentials = styled.p`
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 16px;
  font-weight: 300 !important;
  line-height: 26px;
  letter-spacing: 0.01em !important;
  text-align: left;
  margin-top: 0px;
  color: rgba(35, 41, 38, 1);
  margin-bottom: 0px;
`;

export default TeamMember;
