import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { inputTheme } from 'utilities/theme';

const Input: React.FC<{
  value: string | undefined;
  placeholder?: string;
  type: string;
  name: string;
  id: string;
  ref?: any;
  onChange?: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
  theme?: string;
  onKeyDown?: any;
}> = ({
  value,
  placeholder,
  onChange,
  type,
  name,
  id,
  ref,
  disabled = false,
  theme,
  onKeyDown
}) => {
  return (
    <Field
      name={name}
      id={id}
      ref={ref}
      disabled={disabled}
      theme={theme}
      value={value}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          onKeyDown();
        }
      }}
      onChange={(e) => {
        if (!disabled && onChange) {
          onChange(e.target.value);
        }
      }}
      placeholder={placeholder}
      type={type}
    />
  );
};

interface DisabledProps {
  disabled: boolean;
  theme: string;
}
const Field = styled.input<DisabledProps>`
  background-color: ${(props) =>
    !props.disabled && props.theme === 'light'
      ? '#ffffff'
      : !props.disabled && props.theme === 'file'
      ? 'unset'
      : (!props.disabled && props.theme === 'form') ||
        (!props.disabled && props.theme === 'grid')
      ? '#F5F8F9'
      : props.disabled
      ? '#F3F3F3'
      : props.theme === 'contact'
      ? '#f5f8f9'
      : 'unset'};
  border-radius: unset;
  height: 53px;
  ${(props) => (props.theme ? inputTheme : inputTheme)};
  text-indent: 10px;
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 18px;
  padding: 0px;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-family: Roboto, 'Roboto Fallback' !important;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-family: Roboto, 'Roboto Fallback' !important;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-family: Roboto, 'Roboto Fallback' !important;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-family: Roboto, 'Roboto Fallback' !important;
    padding-left: 5px;
    font-size: 18px;
    color: #000000;
    opacity: 0.4;
  }
  &:focus {
    outline: 2px dotted #000000;
  }
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
  }
`;

export default Input;
