import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { AdvisorHybrid } from 'models/advisor';
import Button from 'components/General/Button';
import ExpertiseCard from 'components/General/ExpertiseCard';
import TeamMember from 'components/TeamMember';
import Icon from 'components/General/Icon';
import ContactModal from 'components/Screens/ContactModal';

import AYWButton from 'assets/images/AYW_Button.svg';
import image from 'assets/images/placeholder.svg';
import phoneIcon from 'assets/images/phone.svg';
import webIcon from 'assets/images/web.svg';
import FacebookIcon from 'assets/images/Facebook.svg';
import LinkedInIcon from 'assets/images/LinkedIn.svg';
import backArrow from 'assets/images/backArrow.svg';
import { ReactGA4Event } from 'utilities/reactGA4Event';

const AdvisorScreen: React.FC<{
  selectedAdvisor: AdvisorHybrid;
  goBack: () => void;
}> = ({ selectedAdvisor, goBack }) => {
  const [modalActive, setModalActive] = useState<boolean>(false);

  useEffect(() => {
    document.getElementById('advisorfinder-appended-div')?.scrollIntoView();
  }, []);

  const getAdvisorName = () => {
    if (
      selectedAdvisor.advisorFields.primaryOffice?.friendlyTeamName ===
      selectedAdvisor.advisorFields.name
    ) {
      return null;
    }
    if (
      selectedAdvisor.advisorFields.primaryOffice?.friendlyTeamName !==
      selectedAdvisor.advisorFields.name
    ) {
      return selectedAdvisor.advisorFields.primaryOffice?.friendlyTeamName;
    }
    return selectedAdvisor.teamFields.friendlyTeamName;
  };

  const getField = (field: string) => {
    if (
      selectedAdvisor.advisorFields.primaryOffice &&
      // @ts-expect-error
      selectedAdvisor.advisorFields.primaryOffice[`${field}`]
    ) {
      // @ts-expect-error
      return selectedAdvisor.advisorFields.primaryOffice[`${field}`];
    }
    // @ts-expect-error
    return selectedAdvisor.advisorFields[`${field}`];
  };

  const getAddress = () => (
    <>
      {' '}
      {getField('address')}
      <br />
      {`${getField('city')} ${getField('province')} ${getField('postalCode')}`}
    </>
  );

  const getURL = () =>
    `https://www.google.com/maps/search/?api=1&query=${getField(
      'address'
    )}+${getField('city')}+${getField('province')}+${getField(
      'postalCode'
    )}+IPC+Investment+Corporation`;

  return (
    <Container>
      <HeroContainer>
        <Controls onClick={goBack}>
          <Icon alt="Back Arrow" iconWidth="20" image={backArrow} />
          <Button type="button" theme="Back" text="Go back" />
        </Controls>
        <HeroDetails>
          <HeroColumn>
            <PhotoInfo>
              <Photo
                alt={`${getField('name')}'s photograph`}
                src={selectedAdvisor.advisorFields.photo || image}
              />
            </PhotoInfo>
          </HeroColumn>
          <HeroColumn>
            <PersonInfo>
              {getField('adviceYourWay') ? (
                <img width="169" alt="Advice Your Way" src={AYWButton} />
              ) : null}
              <HeroText>{selectedAdvisor.advisorFields.name}</HeroText>
              <Title>{selectedAdvisor.advisorFields.title}</Title>

              <FriendlyTeamName>{getAdvisorName()}</FriendlyTeamName>
              <Address
                onClick={() => {
                  ReactGA4Event('event', 'click', {
                    link_category: 'find_an_advisor',
                    advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                    link_id: 'viewMap',
                    link_text: `${getField('address')} -- ${getField(
                      'city'
                    )} ${getField('province')} ${getField('postalCode')}`,
                    link_url: `${getURL()}`
                  });
                }}
                id="viewMap"
                target="_blank"
                href={getURL()}
              >
                {getAddress()}
              </Address>
              <div style={{ backgroundColor: '#EBF5EE', width: 'fit-content' }}>
                <Button
                  type="button"
                  handleClick={() => {
                    if (selectedAdvisor.advisorFields.bookAnAppointment) {
                      ReactGA4Event('event', 'internal_click', {
                        link_category: 'find_an_advisor',
                        advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                        link_id: 'openAdvisorPopup',
                        link_text: 'Book an Appointment',
                        link_url: 'openAdvisorPopup'
                      });
                      window.open(
                        `${selectedAdvisor.advisorFields.bookAnAppointment}`,
                        '_blank'
                      );
                    } else {
                      ReactGA4Event('event', 'internal_click', {
                        link_category: 'find_an_advisor',
                        advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                        link_id: 'openAdvisorPopup',
                        link_text: 'Start a conversation',
                        link_url: 'openAdvisorPopup'
                      });
                      setModalActive(true);
                    }
                  }}
                  theme="CTA"
                  uniqueId="openAdvisorPopup"
                  text={
                    selectedAdvisor.advisorFields.bookAnAppointment
                      ? 'Book an Appointment'
                      : 'Start a conversation'
                  }
                />
              </div>
            </PersonInfo>
          </HeroColumn>
          <HeroColumn>
            <Info>
              <ContactMeTitle>Contact me virtually or in person</ContactMeTitle>
              <div style={{ marginBottom: '24px' }}>
                <Icon
                  handleClick={() =>
                    ReactGA4Event('event', 'click', {
                      link_category: 'find_an_advisor',
                      advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                      link_id: 'clickToCall',
                      link_url: `${getField('phone')}`
                    })
                  }
                  alt=""
                  image={phoneIcon}
                  phone={getField('phone')}
                />
              </div>
              <div style={{ marginBottom: '24px' }}>
                <Icon
                  uniqueId="websiteClick"
                  handleClick={() =>
                    ReactGA4Event('event', 'click', {
                      link_category: 'find_an_advisor',
                      advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                      link_id: 'websiteClick',
                      link_url: `${getField('website')}`
                    })
                  }
                  alt=""
                  image={webIcon}
                  website={getField('website')}
                />
              </div>
              {selectedAdvisor.advisorFields.facebook ||
              selectedAdvisor.advisorFields.linkedIn ? (
                <Socials>
                  {selectedAdvisor.advisorFields.facebook ? (
                    <a
                      id="facebookClick"
                      href={selectedAdvisor.advisorFields.facebook}
                      target="_blank"
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        ReactGA4Event('event', 'click', {
                          link_category: 'find_an_advisor',
                          advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                          link_id: 'facebookClick',
                          link_url: `${selectedAdvisor.advisorFields.facebook}`
                        });
                      }}
                    >
                      <img alt="Facebook Icon" src={FacebookIcon} />
                    </a>
                  ) : null}
                  {selectedAdvisor.advisorFields.linkedIn ? (
                    <a
                      id="linkedinClick"
                      href={selectedAdvisor.advisorFields.linkedIn}
                      target="_blank"
                      onClick={() => {
                        ReactGA4Event('event', 'click', {
                          link_category: 'find_an_advisor',
                          advisor: `${selectedAdvisor.advisorFields.name} -- ${selectedAdvisor.advisorFields.email}`,
                          link_id: 'linkedinClick',
                          link_url: `${selectedAdvisor.advisorFields.linkedIn}`
                        });
                      }}
                    >
                      <img alt="LinkedIn Icon" src={LinkedInIcon} />
                    </a>
                  ) : null}
                </Socials>
              ) : null}
            </Info>
          </HeroColumn>
        </HeroDetails>
      </HeroContainer>
      <DetailsContainer>
        <SmallTitle>My Expertise</SmallTitle>
        <Border />
        <ExpertiseContainer>
          {selectedAdvisor?.advisorFields?.expertise
            ? selectedAdvisor?.advisorFields?.expertise.map((card, index) => {
                return <ExpertiseCard text={card} key={`${index}-${card}`} />;
              })
            : null}
        </ExpertiseContainer>
        <Text>{getField('descriptionParagraph')}</Text>
        <Team>
          <SmallTitle>Our Team</SmallTitle>
          <TeamList>
            {selectedAdvisor?.teamFields?.advisorTeam &&
            selectedAdvisor?.teamFields?.advisorTeam.length > 0
              ? selectedAdvisor.teamFields.advisorTeam.map((teamMember) => {
                  return (
                    <TeamMember
                      key={`${teamMember.name}`}
                      photo={teamMember.photo}
                      name={teamMember.name}
                      credentials={teamMember.title}
                    />
                  );
                })
              : null}
          </TeamList>
        </Team>
        <OfficeInfo>
          <SmallTitle>Office Information</SmallTitle>
          <AddressColumn>
            <MiniTitle>Address</MiniTitle>
            <FriendlyTeamNameText>
              {getField('friendlyTeamName')}
            </FriendlyTeamNameText>
            <AddressText>{getField('address')}</AddressText>
            <AddressText>
              {`${getField('city')} ${getField('province')} `}
            </AddressText>
            <AddressText>{`${getField('postalCode')}`}</AddressText>
            <MiniTitle>Contact Information</MiniTitle>
            <ContactText>{`Phone: ${getField('phone')}`}</ContactText>
            <ContactText>
              Website:{' '}
              <ContactColumnWebsite
                target="_blank"
                href={`https://${getField('website')}`}
              >
                {getField('website')}
              </ContactColumnWebsite>
            </ContactText>
          </AddressColumn>
          <ContactColumn>
            <MiniTitle>Office Hours</MiniTitle>
            <HoursWeek>
              <Date>
                <DateText>Sunday</DateText>
                <DateText>
                  {selectedAdvisor.advisorFields.primaryOffice
                    ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                        ?.Sunday
                    : selectedAdvisor.teamFields.officeHours?.Sunday}
                </DateText>
              </Date>
              <Date>
                <DateText>Monday</DateText>
                <DateText>
                  {selectedAdvisor.advisorFields.primaryOffice
                    ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                        ?.Monday
                    : selectedAdvisor.teamFields.officeHours?.Monday}
                </DateText>
              </Date>
              <Date>
                <DateText>Tuesday</DateText>
                <DateText>
                  {selectedAdvisor.advisorFields.primaryOffice
                    ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                        ?.Tuesday
                    : selectedAdvisor.teamFields.officeHours?.Tuesday}
                </DateText>
              </Date>
              <Date>
                <DateText>Wednesday</DateText>
                <DateText>
                  {selectedAdvisor.advisorFields.primaryOffice
                    ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                        ?.Wednesday
                    : selectedAdvisor.teamFields.officeHours?.Wednesday}
                </DateText>
              </Date>
              <Date>
                <DateText>Thursday</DateText>
                <DateText>
                  {selectedAdvisor.advisorFields.primaryOffice
                    ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                        ?.Thursday
                    : selectedAdvisor.teamFields.officeHours?.Thursday}
                </DateText>
              </Date>
              <Date>
                <DateText>Friday</DateText>
                <DateText>
                  {selectedAdvisor.advisorFields.primaryOffice
                    ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                        ?.Friday
                    : selectedAdvisor.teamFields.officeHours?.Friday}
                </DateText>
              </Date>
              <Date>
                <DateText>Saturday</DateText>
                <DateText>
                  {selectedAdvisor.advisorFields.primaryOffice
                    ? selectedAdvisor.advisorFields.primaryOffice.officeHours
                        ?.Saturday
                    : selectedAdvisor.teamFields.officeHours?.Saturday}
                </DateText>
              </Date>
            </HoursWeek>
          </ContactColumn>
        </OfficeInfo>
      </DetailsContainer>
      <Controls onClick={goBack}>
        <Icon alt="Back Arrow" iconWidth="20" image={backArrow} />
        <Button type="button" theme="Back" text="Go back" />
      </Controls>
      <ContactModal
        modalActive={modalActive}
        setModalActive={setModalActive}
        teamName={selectedAdvisor.advisorFields.name}
        advisorRecipient={selectedAdvisor.advisorFields.email}
      />
    </Container>
  );
};

const Container = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 150px;
  @media screen and (max-width: 980px) {
    padding-top: 90px;
  }
`;

const Controls = styled.div`
  display: flex;
  background-color: transparent;
  padding: 30px 0px;
  margin: 0px 30px;
  margin-right: auto;
  &:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0px;
    margin: 0px 15px;
    margin-right: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding: 10px 0px;
  }
`;

const HeroContainer = styled.div`
  background-color: #f0f6f3;
  width: 100%;
  min-height: 536px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    min-height: 400px;
  }
`;

const HeroDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 80%;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 90%;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 90%;
  }
  padding-bottom: 30px;
`;

const HeroColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  @media screen and (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
`;

const PersonInfo = styled.div`
  padding: 30px 30px 30px 0px;
  min-height: 300px;
  flex-direction: column;
  display: flex;
  padding: 0px 10px 0px 20px;
  justify-content: space-evenly;
  margin-left: 30px;
  @media screen and (max-width: 767px) {
    padding: 10px 0px 10px 0px;
    min-height: 260px;
    align-items: center;
    margin: 0px;
  }
`;

const Info = styled.div`
  padding: 30px;

  background-color: #ffffff;
  border: 1px solid #d4e7ed;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  box-shadow: 0px 4px 12px rgba(37, 47, 35, 0.15);
  @media screen and (max-width: 767px) {
    min-height: 160px;
    padding: 20px;
    justify-content: space-between;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    min-height: 210px;
  }
`;

const FriendlyTeamNameText = styled.p`
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.01em !important;
  text-align: left;
  color: rgba(35, 41, 38, 1);
`;
const AddressText = styled.p`
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 30px;
  letter-spacing: 0.01em !important;
  text-align: left;
  @media screen and (max-width: 767px) {
  }
`;

const OfficeInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  margin-bottom: 40px;
  background-color: #ffffff;
  border: 1px solid rgba(212, 231, 237, 1);
  box-shadow: 0px 4px 12px 0px rgba(37, 47, 35, 0.15);
  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding: 20px;
  }
`;

const ExpertiseContainer = styled.div`
  display: flex;
  margin-bottom: 0px;
  height: auto;
  width: 100%;
  flex-wrap: wrap;
`;

/* Text */

const SmallTitle = styled.h3`
  font-family: Playfair Display !important;
  font-size: 32px !important;
  font-weight: 500 !important;
  line-height: 53px;
  letter-spacing: 0.01em !important;
  text-align: left;
  width: 90%;
  margin: 0px;
  margin-bottom: 25px;
  color: rgba(0, 50, 30, 1) !important;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    line-height: 125%;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 26px;
    line-height: 125%;
  }
`;

const MiniTitle = styled.p`
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0.01em !important;
  text-align: left;
  color: rgba(35, 41, 38, 1);
  margin-top: 0px;
`;

const Text = styled.p`
  padding: 40px 0px;
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 20px !important;
  font-weight: 300 !important;
  line-height: 40px;
  letter-spacing: 0.01em !important;
  text-align: left;
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 32px;
    padding: 20px 0px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding: 0px 0px;
  }
`;

const HeroText = styled.h2`
  text-transform: none !important;
  text-align: left;
  padding: 0px;
  line-height: 60px !important;
  margin: 0px;
  font-family: Playfair Display !important;
  font-size: 44px !important;
  font-weight: 500 !important;
  letter-spacing: 0.01em !important;
  color: rgba(0, 50, 30, 1) !important;
  margin-top: 5px;
  @media screen and (max-width: 767px) {
    font-size: 32px !important;
    text-align: center;
    margin-top: 0px;
    line-height: 42px;
    padding: 0px 0px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 36px;
  }
  line-height: 44px;
`;

const PhotoInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

const ContactText = styled.p`
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 30px;
  letter-spacing: 0.01em !important;
  text-align: left;
  color: rgba(35, 41, 38, 1);
  @media screen and (max-width: 767px) {
    margin-bottom: 5px;
  }
`;

const DateText = styled.p`
  margin-top: 0px;
  color: rgba(35, 41, 38, 1);
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 30px;
  letter-spacing: 0.01em !important;
  text-align: left;
`;

const Address = styled.a`
  line-height: 26.4px;
  letter-spacing: 0.5px;
  margin: 0px;
  color: rgba(35, 41, 38, 1);
  text-decoration: auto;
  margin-bottom: 20px;
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 18px;
  font-weight: 300 !important;
  line-height: 30px;
  letter-spacing: 0.02em;
  text-align: left;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
  &:hover {
    cursor: pointer;
  }
`;

const FriendlyTeamName = styled.p`
  line-height: 26.4px;
  letter-spacing: 0.5px;
  font-family: Roboto, 'Roboto Fallback' !important;
  margin: 0px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  margin-top: 30px;
  font-size: 18px;
  color: #000000;
`;

const Title = styled.p`
  color: rgba(35, 41, 38, 1);
  margin: 0px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  margin-top: 20px;
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0.02em;
  text-align: left;
`;

const ContactColumnWebsite = styled.a`
  color: #000000;
  word-break: break-word;
  text-decoration: underline;
`;

/* End Text */

const Photo = styled.img`
  width: 300px;
  height: auto
  filter: drop-shadow(0px 4px 12px rgba(37, 47, 35, 0.15));
  @media screen and (max-width: 767px) {
    width: 240px;
    height: 240px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    width: 260px;
    height: 260px;
  }
`;

const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25%;
  align-items: center;
`;

const Border = styled.hr`
  background-color: rgba(118, 190, 67, 1);
  height: 1px;
  border: unset;
  margin-bottom: 20px;
`;

const AddressColumn = styled.div`
  width: 47%;
  font-size: 18px;
  @media screen and (max-width: 767px) {
    width: 100%;
    font-size: 16px;
  }
`;

const ContactColumn = styled.div`
  width: 47%;
  font-size: 18px;
  @media screen and (max-width: 767px) {
    width: 100%;
    font-size: 16px;
  }
`;

const Team = styled.div`
  padding-bottom: 40px;
  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    padding-bottom: 20px;
  }
`;

const TeamList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Date = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HoursWeek = styled.div`
  display: flex;
  flex-direction: column;
  }
`;

const DetailsContainer = styled.div`
  padding-top: 30px;
  width: 65%;

  margin: 0 auto;
  @media screen and (max-width: 767px) {
    width: 90%;
  }
`;

const ContactMeTitle = styled.div`
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 32px !important;
  font-weight: 300 !important;
  line-height: 43px !important;
  letter-spacing: 0.01em !important;
  text-align: left;
  width: 90%;
  margin: 0px;
  margin-bottom: 25px;
  color: rgba(35, 41, 38, 1) !important;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    line-height: 125%;
  }
  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 26px;
    line-height: 125%;
  }
`;

export default AdvisorScreen;
