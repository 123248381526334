import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { CoordinatePair } from 'models/coordinate';
import { AdvisorHybrid } from 'models/advisor';
// import Filter from 'components/General/Filter';
import SearchBar from 'components/General/SearchBar';
import Checkmark from 'assets/images/checkmark.svg';

const SearchScreen: React.FC<{
  allAdvisors: AdvisorHybrid[];
  setUserLocation: Dispatch<SetStateAction<CoordinatePair | undefined>>;
  collectSearchFieldAdvisors: (searchString: string) => void;
  collectAddressBasedAdvisors: () => void;
  loading: boolean;
  setMapboxSearchType: Dispatch<SetStateAction<string | undefined>>;
  setSearchType: Dispatch<SetStateAction<string>>;
  searchType: string;
  setInitialSearchPerformed: Dispatch<SetStateAction<boolean>>;
}> = ({
  setUserLocation,
  collectSearchFieldAdvisors,
  collectAddressBasedAdvisors,
  loading,
  setMapboxSearchType,
  setSearchType,
  searchType,
  setInitialSearchPerformed
}) => {
  return (
    <SearchContainer>
      <Heading>
        Take The{' '}
        <span style={{ color: 'rgba(110, 169, 70, 1)' }}>Next Step</span> for a
      </Heading>
      <Heading>More Personalized Wealth Strategy</Heading>
      <Text>Find an IPC Private Wealth Advisor</Text>
      <Form>
        <SearchBar
          loading={loading}
          setUserLocation={setUserLocation}
          collectSearchFieldAdvisors={collectSearchFieldAdvisors}
          collectAddressBasedAdvisors={collectAddressBasedAdvisors}
          setMapboxSearchType={setMapboxSearchType}
          searchType={searchType}
          setInitialSearchPerformed={setInitialSearchPerformed}
        />
      </Form>
      <RadioFlex>
        <RadioWrapper>
          <Radio
            name={'Search by location'}
            id={'Search by location'}
            type="radio"
            checked={searchType === 'location'}
            onChange={() => {
              setSearchType('location');
            }}
          />
          <RadioLabel htmlFor={'Search by location'}>
            {'Search by location'}
          </RadioLabel>
        </RadioWrapper>
        <RadioWrapper>
          <Radio
            name={'Search by Advisor name'}
            id={'Search by Advisor name'}
            type="radio"
            checked={searchType === 'advisorName'}
            onChange={() => {
              setSearchType('advisorName');
            }}
          />
          <RadioLabel htmlFor={'Search by Advisor name'}>
            {'Search by Advisor name'}
          </RadioLabel>
        </RadioWrapper>
      </RadioFlex>

      {/* <Filter allAdvisors={allAdvisors} /> */}
    </SearchContainer>
  );
};

const RadioFlex = styled.div`
  display: flex;
  margin-top: 5px;
  align-items: center;
  width: auto;
  position: relative;
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  position: relative;
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const RadioLabel = styled.label`
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 14px;
  font-weight: 300 !important;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  cursor: pointer;
  width: 200px;

  &::before {
    content: '';
    position: absolute;
    left: -39.51px;
    border-radius: 50%;
    /* Fixed alignment in some browsers. */
    top: -5px;

    background: #f5f8f9;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 25px;
    height: 25px;
  }

  &::after {
    content: '';
    position: absolute;
    left: -34.5px;
    top: 0px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  &:focus {
    outline: 2px dotted #000000;
  }
`;
const Radio = styled.input`
  opacity: 0;
  &:checked {
    + ${RadioLabel}::after {
      width: 16px;
      background-image: url(${Checkmark});
      height: 16px;
      display: inline-block;
      background-size: 16px;
      background-repeat: no-repeat;
      content: "";
    }
  }
  &:focus {
    + ${RadioLabel}::before {
      outline: 2px dotted #000000;
    }
`;
const SearchContainer = styled.div`
  background-color: rgba(243, 245, 242, 1);
  min-height: 350px;
  padding: 30px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    padding: 20px 0px 10px;
  }
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 10px;
  width: 50%;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

const Heading = styled.h1`
  font-family: Playfair Display !important;
  font-size: 32px !important;
  font-weight: 500 !important;
  line-height: 43px !important;
  letter-spacing: 0.01em !important;
  text-align: center;
  color: rgba(0, 50, 30, 1) !important;
  margin: 0px;
`;

const Text = styled.p`
  font-family: Roboto, 'Roboto Fallback' !important;
  font-size: 16px;
  font-weight: 300 !important;
  line-height: 32px;
  letter-spacing: 0.02em;
  text-align: center;
  color: rgba(35, 41, 38, 1);
  @media screen and (max-width: 768px) {
    width: 90%;
    font-size: 18px;
  }
`;

export default SearchScreen;
